body,
html {
  padding: 0;
  margin: 0;
  line-height: 1.5;
  height: 100%;
  overflow: auto;
  -webkit-tap-highlight-color: transparent;
}

#page-loader {
  z-index: 190 !important;
}

code span {
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace !important;
}

.ps__rail-y {
  opacity: 0.2 !important;
}

#__next {
  height: 100%;
}

#__modals {
  overflow: visible;
}

*,
::before,
::after {
  box-sizing: border-box;
  border-width: 0;
}

p {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

a {
  background: none;
  text-decoration: none;
}

button {
  background: none;
  appearance: none;
  overflow: visible;
}

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

img,
video {
  max-width: 100%;
  height: auto;
}

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
  margin: 0;
}

.ps__thumb-y {
  opacity: 0;
}

.scrollbar-container:hover .ps__thumb-y {
  opacity: 0.5;
}
